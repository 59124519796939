import React, { useContext, useState } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import ResponsiveSpacing from "../components/responsive-spacing"
import ExternalStories from "../components/ext-stories"
import HalfHero from "../components/half-hero"
import Heading from "../components/heading"
import Center from "../components/center"
import BottomColorNav from "../components/bottom-color-nav"
import SVG from "../components/svg"
import CTA from "../components/cta"
import postPath from "../utils/post-path"

const ReleaseStyled = styled.div`
  padding: 10px;
  max-width: 360px;
  width: 100%;
  height: auto;

  p {
    font-size: 1.4em;
    line-height: 2.1em;
    color: ${(p) => p.theme.DarkBlue};
  }

  h3 {
    margin: 5px 0 20px 0;
    font-size: 1.8em;
    color: ${(p) => p.theme.DarkBlue};
  }

  a {
    font-family: "interstate-compressed";
    margin: 15px 0 0 0;
    font-size: 1.8em;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: ${(p) => p.theme.DarkBlue};

    &:hover {
      text-decoration: underline;
    }
  }

  .svg {
    color: ${(p) => p.theme.DarkBlue};
    height: 16px;
    margin-left: 10px;
  }
`

const Release = ({ children }) => {
  return <ReleaseStyled>{children}</ReleaseStyled>
}

const PressReleasesStyled = styled.div`
  margin: 50px auto 0 auto;
  width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const PressReleases = ({ fields }) => {
  const theme = useContext(ThemeContext)

  return (
    <PressReleasesStyled>
      {fields.map((a, i) => (
        <Release key={i}>
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: a.acf.thumb_headline,
            }}
          />
          <SVG name="SWave" className="wave" color={theme.Blue} />
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: a.acf.thumb_blurb,
            }}
          />
          <a href={postPath("/news/", a)}>
            <CTA>VIEW PRESS RELEASE</CTA>
          </a>
        </Release>
      ))}
    </PressReleasesStyled>
  )
}

const NewsStyled = styled.div``
const MAX_AT_FIRST = 6

const News = ({ fields, news }) => {
  const [showMore1, setShowMore1] = useState(false)
  const [showMore2, setShowMore2] = useState(false)
  const numArticles = fields.external_stories.articles.length
  const numReleases = news.length
  const articles = fields.external_stories.articles.filter((f, i) => {
    return i < (showMore1 ? Infinity : MAX_AT_FIRST)
  })
  const prs = news.filter((f, i) => {
    return i < (showMore2 ? Infinity : MAX_AT_FIRST)
  })

  return (
    <NewsStyled>
      <HalfHero fields={fields} />
      <Heading margin="50px 0 0 0">
        <h2
          tabIndex="0"
          dangerouslySetInnerHTML={{
            __html: fields.external_stories.headline,
          }}
        />
      </Heading>
      <ResponsiveSpacing>
        <ExternalStories articles={articles} />
      </ResponsiveSpacing>

      <Center margin="50px 0 150px 0">
        {!showMore1 && numArticles > MAX_AT_FIRST ? (
          <Button
            icon="-"
            onClick={(e) => {
              setShowMore1(true)
            }}
          >
            {fields.external_stories.more_button_label}
          </Button>
        ) : (
          ""
        )}
      </Center>
      {!fields.in_the_press.hide_from_view ? (
        <div className="PRCont">
          <Heading margin="50px 0 0 0">
            <h2
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: fields.in_the_press.headline,
              }}
            />
          </Heading>
          <PressReleases fields={prs} />
          <Center margin="50px 0 150px 0">
            {!showMore2 && numReleases > MAX_AT_FIRST ? (
              <Button
                icon="-"
                onClick={(e) => {
                  setShowMore2(true)
                }}
              >
                {fields.in_the_press.more_button_label}
              </Button>
            ) : (
              ""
            )}
          </Center>
        </div>
      ) : (
        ""
      )}
    </NewsStyled>
  )
}

const ConnectedNews = connect((state) => state)(News)

const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      news: allWordpressWpNews {
        edges {
          node {
            title
            content
            acf {
              thumb_headline
              thumb_blurb
            }
          }
        }
      }
      page: allWordpressPage(filter: { slug: { eq: "news-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                headline
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              external_stories {
                headline
                more_button_label
                articles {
                  headline
                  source_name
                  date
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 360) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  article_link
                }
              }
              in_the_press {
                headline
                hide_from_view
                more_button_label
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.page.edges[0].node.acf
  const news = data.news.edges.map((e) => e.node)
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ConnectedNews fields={fields} news={news} />
      <BottomColorNav />
    </Layout>
  )
}

export default NewsPage
